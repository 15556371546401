// Anpassungen für den Internet Explorer 8
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


@import "variables";


// ***** Meta-Navigation *******************************************************
.nav-meta-search-field {
	padding-top: 5px;
}



// ***** Pagebrowser ***********************************************************
.pagebrowser-prev {
	background-position: left 3px !important;
}

.pagebrowser-next {
	background-position: right 3px !important;
}
